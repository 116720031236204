<template>
  <QBtn
    :to="to"
    :flat="flat"
    :href="href"
    :icon="isClose ? 'uil:times' : iconRight ? undefined : icon"
    :size="size"
    :color="color"
    :target="target"
    :loading="loading"
    :icon-right="iconRight ? iconRight : undefined"
    :dense="isClose ? true : dense"
    :unelevated="unelevated"
    :label="($q.screen.gt.sm || forceLabelOnXs) && computedLabel ? computedLabel : ''"
    type="a"
    @click="$emit(isClose ? 'hide' : 'click')"
  >
    <!-- Set type=a so tooltip shows even if btn is disabled -->
    <ActionTooltip
      v-if="computedTooltip"
      :str="computedTooltip"
      :plan-restricted="planRestricted"
    />
  </QBtn>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'md'
    },
    color: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: 'uil:info'
    },
    label: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    flat: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    unelevated: {
      type: Boolean,
      default: true
    },
    isClose: {
      type: Boolean,
      default: false
    },
    forceLabelOnXs: {
      type: Boolean,
      default: false
    },
    planRestricted: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: undefined
    },
    target: {
      type: String,
      default: undefined
    },
    iconRight: {
      type: String,
      default: undefined
    },
    to: {
      type: Object,
      default: undefined
    },
  },
  emits: ['click', 'hide'],
  computed: {
    computedLabel () {
      return this.label ? this.translationExists(this.label) ? this.$t({ id: this.label }) : this.label : false
    },
    computedTooltip () {
      if (this.loading) return this.$t({ id: 'status.loading' })
      else if (this.isClose) return this.$t({ id: 'prompt.close_button' })
      else if (this.planRestricted) return !this.isSubscribed() || (this.planRestricted === 'dragon' && this.isSubscribed() !== 'dragon') ? this.$t({ id: `notification.tooltip.${this.planRestricted}` }) : false
      else if (this.tooltip) {
        return this.translationExists(this.tooltip) ? this.$t({ id: this.tooltip }) : this.tooltip
      } else return false
    }
  }
}
</script>
