import axios from 'axios'
import shortid from 'shortid'
import { get, has } from 'lodash'
import { Buffer } from 'buffer'
import { sanitizeFilename } from 'hc-core/composables/misc.js'

const publishable = process.env.STELACE_PUBLISHABLE_API_KEY
const urlS3CdnPrefix = `${process.env.AWS_S3_URL}${process.env.AWS_CDN_UPLOAD_PREFIX}`
const apiGatewayMap = (resource) => { // Reflect AWS Gateway API resources
  const map = {
    file: 'file-buffer',
    scan: 'scan',
    upload: 'upload-policy',
  }
  return `${process.env.AWS_API_GATEWAY_URL}${map[resource]}`
}

export default {
  data () {
    return {
      uploaderFiles: [], // Quasar files
      uploaderTransformedFiles: [] // copy to keep Quasar data clean, each file "name" need to be the same
    }
  },
  computed: {
    content () { return this.$store.state.content },
    maxUploadFileSize () {
      return this.maxFileSize || this.content.maxUploadFileSize
    },
    uploadedFiles () {
      return this.uploaderFiles.filter((f) => f.__status === 'uploaded')
    },
    failedFiles () {
      return this.uploaderFiles.filter((f) => f.__status === 'failed')
    },
    isUploadComplete () {
      return this.uploaderFiles.reduce((complete, f) => {
        return complete && (this.uploadedFiles.some((u) => u.name === f.name) || this.failedFiles.some((x) => x.name === f.name))
      }, true)
    },
  },
  watch: {
    isUploadComplete (complete) {
      if (complete && typeof this.afterUploadCompleted === 'function') {
        const transformedUploadedFiles = this.getTransformedUploadedFiles()
        const uploadedOrReused = this.uploaderTransformedFiles
          .filter((u) => u && !this.failedFiles.some((failed) => failed.name === u.name))
          .map((u) => {
            if (u.reused) {
              const uploaded = Object.assign({}, u)
              delete uploaded.reused
              return uploaded
            }
            return transformedUploadedFiles.find((t) => t.name === u.name)
          })

        this.afterUploadCompleted({
          transformedUploadedFiles,
          uploadedOrReused,
          uploadedFiles: this.uploadedFiles, // raw Quasar files
          failedFiles: this.failedFiles
        })
      }
    }
  },
  methods: {
    resetUploader () {
      this.$refs.uploader && this.$refs.uploader.reset()
      this.uploaderFiles = []
      this.uploaderTransformedFiles = []
    },

    uploadFilter (files) {
      return files.filter((f) => {
        const aboveLimit = f.size > this.maxUploadFileSize
        if (aboveLimit) {
          this.notify('error.file_upload_size_limit', {
            i18nValues: {
              // in MB
              limit: Math.round((10 * this.maxUploadFileSize) / Math.pow(1024, 2)) / 10
            },
            options: { timeout: 10000 }
          })
          return false
        }

        const duplicated = this.uploaderTransformedFiles.some((u) => u && files.some((f) => f.name === u.name))
        if (duplicated) {
          this.notify('error.file_upload_duplicate_name', {
            i18nValues: {
              // in MB
              name: files[0].name
            },
            options: { timeout: 10000 }
          })
          return false
        }

        return true
      })
    },

    async uploadFactory (files) {
      // even if multiple files are selected in file picker
      // there is only one element in the array
      const file = files[0]
      const options = {}
      if (this.uploadFolder) options.folder = this.uploadFolder

      return getS3SignedUrl(file, options)
        .then(({ fields: formFields, url, fieldName, S3FileUrl }) => {
          let handler = this.uploadingFileUrlHandler
          handler = typeof handler === 'function' ? handler : (_) => _

          file.remoteUrl = S3FileUrl

          handler({ file, url: S3FileUrl })

          return { formFields, url, fieldName }
        })
        .catch((e) => {
          this.useLogger(e)
        })
    },

    filesAdded (added) {
      this.uploaderFiles = [...this.uploaderFiles, ...added]

      const transformed = this.getTransformedFiles(added)
      this.uploaderTransformedFiles = [...this.uploaderTransformedFiles, ...transformed]
    },

    filesRemoved (removed) {
      this.uploaderFiles = this.uploaderFiles.filter((f) => f && !removed.some((r) => r.name === f.name))
      this.uploaderTransformedFiles = this.uploaderTransformedFiles.filter((f) => f && !removed.some((r) => r.name === f.name))
      // TODO: handle S3 file removal if appropriate
    },

    filesUploading (/* { files: uploading } */) {},

    filesUploaded ({ files: uploaded }) {
      this.uploaderFiles = this.uploaderFiles.filter((f) => f && !uploaded.some((u) => u.name === f.name)).concat(uploaded)

      const transformed = this.getTransformedFiles(uploaded)
      this.uploaderTransformedFiles = this.uploaderTransformedFiles
        .filter((u) => !!u) // consider this object unsafe since it transformed by several parties
        .map((old) => {
          return transformed.find((t) => t && t.name === old.name) || old
        })
    },

    filesFailed ({ files: failed }) {
      this.uploaderFiles = this.uploaderFiles.filter((f) => !failed.some((fail) => fail.name === f.name)).concat(failed)

      const transformed = this.getTransformedFiles(failed)
      this.uploaderTransformedFiles = this.uploaderTransformedFiles
        .filter((u) => !!u) // consider this object unsafe since it transformed by several parties
        .map((old) => {
          return transformed.find((t) => t && t.name === old.name) || old
        })
    },

    getFile ({ files = this.uploaderFiles, name }) {
      return files.find((f) => f && f.name === name) || {}
    },

    getTransformedUploadedFiles (fn) {
      return this.getTransformedFiles(this.uploadedFiles, fn)
    },

    getTransformedFiles (files, fn = () => ({})) {
      return files.reduce((tr, f) => {
        tr.push(
          Object.assign(
            {},
            {
              name: f.name,
              url: f.remoteUrl,
              key: `${f.name}-${f.remoteUrl || f.__img.src}`
            },
            fn(f)
          )
        )
        return tr
      }, [])
    },

    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },

    // ##### Images manipulation #####
    async factoryAws (files) {
      try {
        const file = files[0]
        const options = {}
        if (this.uploadFolder) options.folder = this.uploadFolder
        if (this.uploadPrefix) options.prefix = this.uploadPrefix
        if (this.uploadEntityId) options.id = sanitizeFilename(this.uploadEntityId) // remove all accents and spaces
        if (options.id === 'shortid') options.id = this.$shortid.generate()
        if (options.prefix === 'timestamp') options.prefix = Date.now()

        const { fields: formFields, fieldName, url, S3FileUrl } = await getS3SignedUrl(file, options)
        let handler = this.uploadingFileUrlHandler
        handler = typeof handler === 'function' ? handler : (_) => _

        file.remoteUrl = S3FileUrl

        handler({ file, url: S3FileUrl })

        return { formFields, url, fieldName, method: 'PUT', data: file, sendRaw: true }
      } catch (e) { this.useLogger(e) }
    },

    getFileUrl (filename) { return `${urlS3CdnPrefix}/${filename ? filename.replace(/^\//g, '') : filename}` },

    getFileKey (fileUrl) { return fileUrl.replace(`${urlS3CdnPrefix}`, '').replace(/^\//g, '') },

    // Getting file directly and buffering blob in browser
    async getBlobFromS3 (key) {
      try {
        return await fetch(`${urlS3CdnPrefix}/${key}`)
          .then(res => res.blob()).then((blob) => {
            if (!['application/pdf', 'application/x-www-form-urlencoded'].includes(blob.type)) throw new Error(blob.type)
            return blob
          }).catch(e => { throw new Error(e) })
      } catch (e) {
        this.useLogger(e)
        return null
      }
    },

    async getBufferFromS3 (key) {
      return axios.get(`${apiGatewayMap('file')}?key=${process.env.AWS_CDN_UPLOAD_PREFIX}/` + key, { responseType: 'buffer', contentType: 'application/pdf', headers: { 'x-api-key': publishable } }).then(({ data: Buffer }) => {
        return btoa(Array.prototype.map.call(new Uint8Array(Buffer.data), function (ch) {
          return String.fromCharCode(ch)
        }).join(''))
      })
    },

    async downloadResume (applicant) {
      let url = ''
      // TODO: newResumeWay
      if (this.$_.get(applicant, 'metadata._files.resume') || this.$_.get(applicant, 'resume.metadata._files.resume')) {
        let filekey = this.$_.get(applicant, 'metadata._files.resume', this.$_.get(applicant, 'resume.metadata._files.resume'))
        if (filekey.includes('https://')) filekey = this.getFileKey(filekey)
        const blob = await this.getBlobFromS3(filekey)
        if (!blob) return null
        url = URL.createObjectURL(blob)
      } else if (this.$_.get(applicant, 'metadata._resume.file')) {
        url = 'https://api.happycab.fr/api/attachments/applicant_resume/download/' + applicant.metadata._resume.file
      } else if (this.$_.get(applicant, 'metadata._resume.indeed.file')) {
        url = 'data:application/pdf;base64,' + applicant.metadata._resume.indeed.file.data
      }
      const link = document.createElement('a')
      link.href = url
      link.download = `CV-${applicant.firstname ?? ''}-${applicant.lastname ?? ''}_HappyCab.pdf`
      link.click()
      URL.revokeObjectURL(link.href)
    },

    // Generic way to downlaod file
    // TODO : for now used here in mixins, at here move the whole mixins to composable/aws
    async dlKeyFromS3 ({ key, type, dlLabel = undefined }) {
      if (!type || !key) return null
      switch (type) {
        case 'pdf':
          const blob = await this.getBlobFromS3(key) /* eslint-disable-line no-case-declarations */
          if (!blob) return null
          const link = document.createElement('a') /* eslint-disable-line no-case-declarations */
          link.href = URL.createObjectURL(blob)
          link.download = sanitizeFilename(`${dlLabel ?? key.split('/').pop()}_HappyCab.pdf`)
          link.click()
          URL.revokeObjectURL(link.href)
          break
        case 'image':
          // TODO implement this
          break

        default:
          break
      }
    },
  },
}

/**
 * @param  {Object} file - having name and type properties
 * @param  {Object} [options]
 * @param  {Object} [options.folder] - For easier maintenance add S3 "folder" prefix (without leading /)
 */
export async function getS3SignedUrl (file, { folder = 'images/tmp', prefix = '', id = '' } = {}) {
  const headers = { 'x-api-key': publishable }
  let filename = id + '.' + file.name.split('.').pop()
  if (prefix !== '') filename = prefix + '_' + filename
  return axios.post( // get AWS S3 signed URL from Lambda behind API Getaway
    apiGatewayMap('upload'),
    {
      filename,
      folder: cleanPrefix(process.env.AWS_CDN_UPLOAD_PREFIX) + folder
    },
    { headers }
  )
    .then(({ data }) => {
      const S3Sign = parseS3Url(data.body)
      const baseUrl = S3Sign.endpoint_url
      const S3FileUrl = `${baseUrl}${S3Sign.key}`

      const fieldsHeaders = [{ name: 'content-type', value: file.type }]
      if (prefix === 'resume') {
        fieldsHeaders.push({ name: 'content-disposition', value: 'inline' })
      }

      return {
        S3FileUrl,
        fields: fieldsHeaders,
        url: S3Sign.fullUrl,
        fieldName: 'file' // S3 POST: https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
      }
    })
}

export async function uploadFileToS3 ({ url, file }) {
  const s3Instance = axios.create()
  const response = await s3Instance.put(url, file)
  if (response.status === 200 && has(response, 'config.url')) {
    return response.config.url.split('?')[0]
  } else return null
}

export function getWebpImageUrl (filename, width, height, quality = 100, lossless = true) {
  const encodedUrl = Buffer.from(JSON.stringify({
    bucket: process.env.AWS_CDN_S3_BUCKET,
    key: decodeURIComponent(`${process.env.AWS_CDN_UPLOAD_PREFIX}/${filename ? filename.replace(/^\//g, '') : filename}`),
    edits: {
      webp: { quality: 100, lossless: true }
    }
  })).toString('base64')
  return `${process.env.AWS_CDN_WITH_IMAGE_HANDLER_URL}${encodedUrl}`
}

export function getCroppedImageUrl (filename, width, height, quality = 90) {
  const encodedUrl = Buffer.from(JSON.stringify({
    bucket: process.env.AWS_CDN_S3_BUCKET,
    key: decodeURIComponent(`${process.env.AWS_CDN_UPLOAD_PREFIX}/${filename ? filename.replace(/^\//g, '') : filename}`),
    edits: {
      resize: { width, height, fit: 'cover' },
      webp: { quality }
    }
  })).toString('base64')
  return `${process.env.AWS_CDN_WITH_IMAGE_HANDLER_URL}${encodedUrl}`
}

export function getImageUrl (filename) {
  return `${process.env.AWS_CDN_WITH_IMAGE_HANDLER_URL}${process.env.AWS_CDN_UPLOAD_PREFIX}/${filename ? filename.replace(/^\//g, '') : filename}`
}

function parseS3Url (url) {
  const temp = document.createElement('a')
  temp.href = url
  const baseUrl = temp.origin + '/'
  return {
    endpoint_url: baseUrl,
    fullUrl: url,
    key: unescape(url.split('?')[0].replace(baseUrl, ''))
  }
}

export function cleanPrefix (prefix = '') {
  return prefix
    .replace(/\/{2,}/g, '/')
    .replace(/^\//, '')
    .replace(/([^/]+)(\/)?$/, '$1/') // force trailing slash only if string is not empty
}

export function getNoCacheUrl (key) {
  return `${urlS3CdnPrefix}/${key ? key.replace(/^\//g, '') : key}?t=${Date.now()}`
}

export async function keyAfterUploadS3 ({ file, entity = {}, options = {} }) {
  try {
    if (!file) return null
    let prefix = get(options, 'uploadPrefix', undefined)
    if (prefix === 'timestamp') prefix = Date.now()

    let id = shortid.generate()
    if (get(options, 'uploadEntityId', false)) id = get(options, 'uploadEntityId', id)
    if (get(options, 'useEntityId', false)) id = get(entity, 'id', id)
    if (get(options, 's3KeepFileNameAsIs', false)) {
      prefix = undefined
      id = file.name.split('.')[0]
    }

    // getS3SignedUrl import
    const { url } = await axios.post( // get AWS S3 signed URL from Lambda behind API Getaway
      apiGatewayMap('upload'),
      {
        filename: `${prefix ? `${prefix}_` : ''}${sanitizeFilename(id)}.${file.name.split('.').pop()}`,
        folder: cleanPrefix(process.env.AWS_CDN_UPLOAD_PREFIX) + get(options, 'uploadFolder', 'missingUploadFolder')
      },
      { headers: { 'x-api-key': publishable } }
    ).then(({ data }) => {
      const S3Sign = parseS3Url(data.body)
      const baseUrl = S3Sign.endpoint_url
      const S3FileUrl = `${baseUrl}${S3Sign.key}`

      const fieldsHeaders = [{ name: 'content-type', value: file.type }]
      if (file.type === 'application.pdf') fieldsHeaders.push({ name: 'content-disposition', value: 'inline' })

      return {
        S3FileUrl,
        fields: fieldsHeaders,
        url: S3Sign.fullUrl,
        fieldName: 'file' // S3 POST: https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
      }
    })

    // uploadFileToS3 import
    const s3Instance = axios.create()
    const response = await s3Instance.put(url, file)
    const fileUrl = (response.status === 200 && has(response, 'config.url')) ? response.config.url.split('?')[0] : null

    // getFileKey import
    return fileUrl ? fileUrl.replace(`${urlS3CdnPrefix}`, '').replace(/^\//g, '') : null
  } catch (e) {
    return null
  }
}

// New, generic, with lambda function, served as cdn, allow noCache, purposed to be used everywhere
// Self reinterpretation of sharp-aws-image-handler-client
export function cdnImg (cdnPath, opt = {}) {
  return `${process.env.AWS_CDN_WITH_IMAGE_HANDLER_URL}${btoa(JSON.stringify({
    bucket: process.env.AWS_CDN_S3_BUCKET,
    key: decodeURIComponent(`${process.env.AWS_CDN_UPLOAD_PREFIX}/${(cdnPath ?? 'platform/branding/placeholder.jpg').replace(/^\//g, '')}`),
    edits: {
      resize: { width: get(opt, 'width', undefined), height: get(opt, 'height', undefined), fit: 'cover' },
      webp: { quality: opt.lossless ? 100 : get(opt, 'quality', 85), lossless: get(opt, 'lossless', false) }
    }
  }))}${get(opt, 'noCache', false) ? `?t=${Date.now()}` : ''}`
}
